import React, { useState, useEffect, useRef } from 'react'
import Header from '../../components/Header'
import {
    Link, useParams
} from "react-router-dom";
import Icon from '../../ui/Icon';
import { useReactToPrint } from 'react-to-print';
import resources from '../../api';
import config from '../../constants';
// import JsPDF from 'jspdf';
import { ComponentToPrint } from '../../components/ComponentToPrint';
import Material from '../../components/Material';
import {
    Button, ButtonGroup, Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
} from '@chakra-ui/react'
import {
    FiPlusCircle,
    FiFilter,
    FiChevronDown,
    FiCode
} from 'react-icons/fi';

import {
    ImFileExcel,
    ImFilePdf
} from 'react-icons/im';

export default function View() {
    const [data, setData] = useState({})
    const [code, setCode] = useState(0)
    const { id } = useParams()


    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    // const generatePDF = () => {

    //     const report = new JsPDF('portrait', 'pt', 'a4');
    //     report.html(document.querySelector('#fiche')).then(() => {
    //         report.save('fiche.pdf');
    //     })
    // }
    async function onGet() {

        let mat = await resources.material.readOne(id)
        if (mat.status === 200) {

            setData(mat.data)


        }


    }

    useEffect(() => {
        onGet();
    }, [])


    function sliceIntoChunks(arr, chunkSize) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    function switchCode(params) {

        if (code == 0) {
            setCode(1)
        } else {
            setCode(0)
        }

    }




    return (
        <div>

            <div className="row">
                <div className="col">
                    <h3 className='mt-3'>Materials</h3>
                    <div className="d-flex justify-content-between">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page"><Link
                                    className="text-muted" to="/materials">Materials</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">update material</li>
                            </ol>
                        </nav>

                        <ButtonGroup gap='2'>
                            <Button leftIcon={<ImFileExcel />} onClick={handlePrint} colorScheme='brand' > Save as pdf</Button>
                            <Button onClick={switchCode} colorScheme='brand' > Switch to {code == 0 ? " qr code" : " bar code"} </Button>
                        </ButtonGroup>



                    </div>

                    <div className='row justify-content-center mt-5'>
                        <div className='col-lg-9 mb-5'>

                            <ComponentToPrint ref={componentRef}>
                                {
                                    data && <Material data={data} code={code}/>
                                }

                            </ComponentToPrint>



                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
